import AddIcon from '@mui/icons-material/Add';
import {
  Box,
  Card,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from '@mui/material';
import { IconComponent } from '@zippeditoolsjs/zippedi-icons';
import { format } from 'date-fns';
import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { titleCase } from '../pipelineMonitoring/Utils';
import AlertBox from '../tools/AlertBox';
import TabPanel from '../tools/TabPanel';
import { a11yProps } from '../Utils';

function RobotStateHistoryWidget(props) {
  const {
    store,
    robot,
    getChangeMotives,
    loadingChangeMotives,
    patchChangeReasonsResponse,
    getRobotState,
    setOpenState,
    getRobotStateHistory,
    robotStateHistory,
    featurePermission,
    checkFeaturePermission,
  } = props;

  const [tabIndex, setTabIndex] = useState(0);
  const { t } = useTranslation();
  const UPDATE_ROBOT_STATE = 'update_robot_state';

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  useEffect(() => {
    checkFeaturePermission(UPDATE_ROBOT_STATE);
  }, []);

  useEffect(() => {
    getRobotStateHistory(`${store}-${robot}`);
  }, [robot]);

  useEffect(() => {
    let robot_id = `${store}-${robot}`;
    getChangeMotives();
    getRobotState(robot_id);
    getRobotStateHistory(`${store}-${robot}`);
  }, [store, robot, patchChangeReasonsResponse]);

  const handleElapsedTime = (created_at, finished_at) => {
    const createdAt = new Date(convertToUserTimeZone(created_at));
    const finishedAt = finished_at
      ? new Date(convertToUserTimeZone(finished_at))
      : new Date();
    const elapsedTime = finishedAt - createdAt;
    const millisecondsInOneDay = 24 * 60 * 60 * 1000;
    const millisecondsInOneHour = 60 * 60 * 1000;

    const days = Math.floor(elapsedTime / millisecondsInOneDay);
    const hours = Math.floor(
      (elapsedTime % millisecondsInOneDay) / millisecondsInOneHour
    );

    if (days > 0) {
      return `${days} ${days === 1 ? 'day' : 'days'}`;
    } else {
      return `${hours} ${hours === 1 ? 'hour' : 'hours'}`;
    }
  };

  const convertToUserTimeZone = (utcDateString) => {
    const utcDate = new Date(utcDateString);
    const timezoneOffset = utcDate.getTimezoneOffset() * 60000;
    const localDate = new Date(utcDate.getTime() - timezoneOffset);

    const year = localDate.getFullYear();
    const month = String(localDate.getMonth() + 1).padStart(2, '0');
    const day = String(localDate.getDate()).padStart(2, '0');
    const hours = String(localDate.getHours()).padStart(2, '0');
    const minutes = String(localDate.getMinutes()).padStart(2, '0');
    const seconds = String(localDate.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
  };

  const handleTime = (element) => {
    return convertToUserTimeZone(element.created_at);
  };

  const handleRobotStateIcon = (robotState) => {
    let icon, colorIcon;
    switch (robotState) {
      case 'active':
        icon = 'checkmark-circle-outline';
        colorIcon = '#0E7F19';
        break;
      case 'not-operational':
        icon = 'power-outline';
        colorIcon = '#fe2d38';
        break;
      case 'under-maintenance':
        icon = 'build-outline';
        colorIcon = '#FFA500';
        break;
      default:
        icon = 'information-circle-outline';
        colorIcon = '#808080';
        break;
    }
    return (
      <IconComponent
        iconName={icon}
        style={{
          fontSize: '27px',
          color: colorIcon,
        }}
      />
    );
  };

  return loadingChangeMotives ? (
    <Box
      variant="rectangular"
      minHeight={250}
      height={'100%'}
      sx={{
        m: 2,
        backgroundColor: '#FFF',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 2,
      }}
    >
      <CircularProgress />
      <Typography>
        {t(
          'overseer_app.widget.robot_state_loading',
          'Loading Robot State History...'
        )}
      </Typography>
    </Box>
  ) : (
    <Card
      sx={{
        m: 2,
        minHeight: 250,
        height: '100%',
      }}
    >
      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          aria-label="basic tabs example"
        >
          <Tab
            label={t(
              'overseer_app.widget.robot_state_history',
              'Robot State History'
            )}
            {...a11yProps(0, { textTransform: 'initial' })}
          />
        </Tabs>
        <Tooltip
          title={
            !featurePermission[UPDATE_ROBOT_STATE]
              ? t("You do not have permission to change the robot's state")
              : null
          }
        >
          <span>
            <IconButton
              color="primary"
              aria-label="add to shopping cart"
              onClick={() => {
                if (featurePermission[UPDATE_ROBOT_STATE]) setOpenState(true);
              }}
              disabled={!featurePermission[UPDATE_ROBOT_STATE]}
            >
              <AddIcon />
            </IconButton>
          </span>
        </Tooltip>
      </Box>
      <TabPanel value={tabIndex} index={0}>
        <Grid container>
          <Grid item xs={12} sx={{ overflowY: 'auto', maxHeight: '42em' }}>
            {robotStateHistory && robotStateHistory.length > 0 ? (
              <List dense={true}>
                {robotStateHistory.map((element, idx) => (
                  <Fragment key={`robotStateHistory-${idx}`}>
                    <ListItem>
                      <Box
                        sx={{
                          marginLeft: 0,
                          marginRight: 1,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          p: 0.5,
                        }}
                      >
                        {handleRobotStateIcon(element.state)}
                      </Box>
                      <ListItemText
                        primary={
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                            }}
                          >
                            <Typography>
                              <strong>{t(`overseer_app.general.${element.state}`, titleCase(element.state))}</strong>
                            </Typography>
                            {idx === 0 && (
                              <Chip
                                label="Actual State"
                                color="success"
                                size="small"
                                style={{ color: 'white' }}
                              />
                            )}
                          </Box>
                        }
                        secondary={
                          <Fragment>
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                marginTop: 1,
                              }}
                            >
                              <Box sx={{ flex: 2 }}>
                                <Typography variant="body1">
                                  <strong>Started at:</strong>{' '}
                                  {element.created_at
                                    ? format(
                                      new Date(handleTime(element)),
                                      'dd/MM/yyyy HH:mm'
                                    )
                                    : '-'}
                                </Typography>
                                <Typography variant="body1">
                                  <strong>Motive:</strong> {element.motive}
                                </Typography>
                                <Typography variant="body1">
                                  <strong>User:</strong> {element.username}
                                </Typography>
                              </Box>
                              <Divider orientation="vertical" flexItem />
                              <Box
                                sx={{
                                  flex: 1,
                                  paddingLeft: 2,
                                  textAlign: 'center',
                                }}
                              >
                                <Typography variant="body1">
                                  {handleElapsedTime(
                                    element.created_at,
                                    element.finished_at
                                  )}
                                </Typography>
                                <Typography variant="caption" component="div">
                                  <strong>Elapsed time</strong>
                                </Typography>
                              </Box>
                            </Box>
                          </Fragment>
                        }
                      />
                    </ListItem>
                    <Divider sx={{ mr: 1 }} />
                  </Fragment>
                ))}
              </List>
            ) : (
              <AlertBox
                severity="info"
                content={t(
                  'overseer_app.widget.no_robot_state_history',
                  'No Robot State History available'
                )}
              />
            )}
          </Grid>
        </Grid>
      </TabPanel>
    </Card>
  );
}

export default RobotStateHistoryWidget;
