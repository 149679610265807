import { Divider, Tooltip, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Icon from '../tools/Icon';

export default function StoreConfirmDialog(props) {
  const { open, action, handleClose, storeCode, handleConfirm } = props;

  const { t } = useTranslation();

  const [modalMessage, setModalMessage] = useState('');
  const modalForm = useRef({});

  useEffect(() => {
    let message;
    if (action === 'delete') {
      message = t(
        'overseer_app.widget.store_delete_confirm_dialog',
        `You are about to delete the ${storeCode} store. Do you want to continue?`,
        { storeCode }
      );
    } else if (action === 'edit') {
      message = t(
        'overseer_app.widget.store_edit_confirm_dialog',
        `You are about to edit the ${storeCode} store. Do you want to continue?`,
        { storeCode }
      );
    }
    setModalMessage(message);
  }, [action]);

  const handleModalConfirm = () => {
    handleConfirm(modalForm.current);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="store-dialog-title"
      aria-describedby="store-dialog-description"
    >
      <DialogTitle id="store-dialog-title">
        <Typography
          variant="h6"
          sx={{ display: 'flex', alignItems: 'baseline' }}
          gutterBottom
        >
          <Icon iconName="warning" color={'warning'} />
          {action} {storeCode}
        </Typography>
        <Divider />
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="store-dialog-description">
          <Typography variant="body" gutterBottom>
            {modalMessage}
          </Typography>
        </DialogContentText>
        <Divider sx={{ my: 1 }} />
      </DialogContent>
      <DialogActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Tooltip title="The action will be canceled">
          <Button onClick={handleClose} variant="outlined">
            No
          </Button>
        </Tooltip>
        <Button
          onClick={handleModalConfirm}
          autoFocus
          variant="contained"
          color="secondary"
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
}
