import RobotIcon from '../../assets/icons/robot.svg';
import { transformRobot2Pixels } from '../Utils';

export const getMousePos = (canvas, event) => {
  let rect = canvas.getBoundingClientRect();
  return {
    x: event.clientX - rect.left,
    y: event.clientY - rect.top,
  };
};

const drawRotatedImage = (image, ctx, x, y, angle, widthPx) => {
  ctx.save();
  // move to the middle of where we want to draw our image
  ctx.translate(x, y);
  // rotate around that point
  ctx.rotate(-angle);
  // draw it up and to the left by half the width
  // and height of the image
  let width = widthPx;
  let height = (widthPx * image.height) / image.width;
  ctx.drawImage(image, -(width / 2), -(height / 2), width, height);
  // and restore the co-ords to how they were when we began
  ctx.restore();
};

export const drawRobotPose = (
  ctx,
  pose,
  info,
  isFollowingRobot = true,
  canvasId = 'responsive-canvas',
  iconName = 'pin'
) => {
  let img = new Image();
  let pxSize = 18;
  let offsetY = 0;

  if (iconName === 'pin') {
    img.src = 'data:image/svg+xml,' + encodeURIComponent(iconoSVG);
  } else {
    img.src = RobotIcon;
    pxSize = 48;
    offsetY = 10;
  }
  img.onload = () => {
    if (pose) {
      // clear previous pose
      ctx.clearRect(0, 0, info.width, info.height);
      let poseToShow = pose;
      let pixel_pos = transformRobot2Pixels(
        poseToShow[0],
        poseToShow[1],
        poseToShow[2],
        info.origin,
        info.resolution,
        info.height
      );
      drawRotatedImage(img, ctx, pixel_pos[0], pixel_pos[1] - offsetY, pixel_pos[2], pxSize);
      if (isFollowingRobot) centerModalMapView(pixel_pos, canvasId);
    }
  };
};

export const drawLaserHits = (ctx, laserPoints, info) => {
  ctx.clearRect(0, 0, info.width, info.height);
  ctx.fillStyle = '#FF0000';
  laserPoints.forEach((pos) => {
    let pixelPos = transformRobot2Pixels(
      pos[0],
      pos[1],
      0,
      info.origin,
      info.resolution,
      info.height
    );
    ctx.beginPath();
    ctx.arc(pixelPos[0], pixelPos[1], 2, 0, 2 * Math.PI);
    ctx.fill();
  });
};

export const drawPath = (ctx, points, info, color) => {
  ctx.clearRect(0, 0, info.width, info.height);
  ctx.fillStyle = color;
  points.forEach((position) => {
    let pixel_pos = transformRobot2Pixels(
      position[0],
      position[1],
      0,
      info.origin,
      info.resolution,
      info.height
    );
    ctx.beginPath();
    ctx.arc(pixel_pos[0], pixel_pos[1], 2, 0, 2 * Math.PI);
    ctx.fill();
  });
};

export const drawHeatmap = (ctx, mapSignal) => {
  let signalMatrix = JSON.parse(mapSignal);
  const height = signalMatrix?.length;
  if (!height) return;

  const width = signalMatrix[0].length;
  const imageData = ctx.createImageData(width, height);
  const data = imageData.data;

  let index = 0;
  signalMatrix.forEach(row => {
    row.forEach(pixel => {
      const [r, g, b] = pixel;
      data[index++] = r;
      data[index++] = g;
      data[index++] = b;
      data[index++] = 128; // Alpha (opacity), 128 is 0.5 in a range of 0-255
    });
  });
  ctx.putImageData(imageData, 0, 0);
};

const translate_point = (point, translation) => {
  return [point[0] + translation[0], point[1] + translation[1]];
};

const rotate_point_around_z = (point, angle) => {
  let s_theta = Math.sin(angle);
  let c_theta = Math.cos(angle);

  // Rotation matrix
  return [
    c_theta * point[0] - s_theta * point[1],
    s_theta * point[0] + c_theta * point[1],
  ];
};

export const rotateAroundRobot = (point, angle, robotPose) => {
  let pose_xy = robotPose.slice(0, 2);
  let neg_pose_xy = pose_xy.map((pos) => -pos);
  // point = point - pose_xy
  let trans_to_origin = translate_point(point, neg_pose_xy);
  // Rotate point around z
  let rotated_point = rotate_point_around_z(trans_to_origin, angle);
  // Translate rotated point
  let result = translate_point(rotated_point, pose_xy);

  return result;
};

const centerModalMapView = (pixelPos, canvasId) => {
  let canvas = document.getElementById(canvasId);
  if (canvas) {
    let canvasContainer = canvas.parentElement;
    let canvasCenterX = pixelPos[0] - canvasContainer.offsetWidth / 2;
    let canvasCenterY = pixelPos[1] - canvasContainer.offsetHeight / 2;
    canvasContainer.scrollTo(canvasCenterX, canvasCenterY);
  }
};

export const canvasDrawOnCircle = (
  ctx,
  coords,
  radius = 3,
  ColorFill = 'rgba(254, 45, 56, 0.4)'
) => {
  ctx.beginPath();
  ctx.arc(coords.x, coords.y, radius, 0, 2 * Math.PI);
  ctx.fillStyle = ColorFill;
  ctx.fill();
};

const canvasDrawOnLine = (
  ctx,
  coords,
  width,
  height,
  ColorFill = '#FFA400',
  lineWidth = 5,
  dash = []
) => {
  ctx.beginPath();
  ctx.moveTo(coords.pxInit.x, coords.pxInit.y);
  ctx.lineTo(coords.pxEnd.x, coords.pxEnd.y);
  ctx.lineWidth = lineWidth;
  ctx.strokeStyle = ColorFill;
  ctx.setLineDash(dash);
  ctx.stroke();
};

export const clearCanvas = (ctx, width, height) => {
  ctx.clearRect(0, 0, width, height);
};

export const drawVirtualBorder = (
  ctx,
  coords,
  width,
  height,
  borderPart,
  isStaticDraw = false
) => {
  if (borderPart === 'point') {
    canvasDrawOnCircle(ctx, coords, 4, '#ffa400');
  } else if (borderPart === 'dashedLine') {
    ctx.clearRect(0, 0, width, height);
    canvasDrawOnLine(ctx, coords, width, height, '#ffa400', 4, [10, 5]);
  } else if (borderPart === 'line') {
    canvasDrawOnLine(ctx, coords, width, height, '#ffa400', 4);
  }
};

export const drawRectangleOnCanvas = (ctx, px, py, width, height, colorFill = 'rgba(255, 164, 0, 0.4)', strokeStyle = "#212529") => {
  ctx.beginPath();
  ctx.fillStyle = colorFill;
  ctx.fillRect(px, py, width, height);
  ctx.strokeStyle = strokeStyle;
  ctx.lineWidth = 0.9;
  ctx.rect(px, py, width, height);
  ctx.stroke();
}

export const drawRectangleWithPattern = (ctx, px, py, width, height, patternColor = '#000000', colorFill = '#FFD700', strokeStyle = "#212529") => {
  // Create a temporary canvas to generate the pattern
  const patternCanvas = document.createElement('canvas');
  const patternContext = patternCanvas.getContext('2d');

  const patternSize = 15; // Size of the pattern
  const [dashLength, dashGap] = [10, 1]; // Size of the dashes and gaps
  patternCanvas.width = patternSize;
  patternCanvas.height = patternSize;

  // Draw the pattern of colorFill and patternColor diagonal lines
  patternContext.fillStyle = colorFill;
  patternContext.fillRect(0, 0, patternSize, patternSize);

  patternContext.strokeStyle = patternColor; // Black
  patternContext.lineWidth = 2; // Width of the black lines
  patternContext.setLineDash([dashLength, dashGap]); // Dashed line
  patternContext.beginPath();
  patternContext.moveTo(0, patternSize); // Diagonal line
  patternContext.lineTo(patternSize, 0);
  patternContext.stroke();

  // Create the pattern from the temporary canvas
  const pattern = ctx.createPattern(patternCanvas, 'repeat');

  // Use the pattern to fill the rectangle
  ctx.beginPath();
  ctx.fillStyle = pattern;
  ctx.fillRect(px, py, width, height);

  // Draw the rectangle border
  ctx.strokeStyle = strokeStyle;
  ctx.lineWidth = 0.9;
  ctx.rect(px, py, width, height);
  ctx.stroke();
};


export const writeAisleOnCanvas = (ctx, text, pixelInit, pixelEnd, isTextOnMiddle = false, font = 'bold 16px verdana, sans-serif') => {
  ctx.save();
  let [xEnd, yEnd] = pixelEnd;
  const [xInit, yInit] = pixelInit;
  const distance_x = xEnd - xInit;
  const distance_y = yEnd - yInit;

  // update the position of the text to be in the middle of the rectangle
  const partPosition = 2;
  xEnd = distance_y > distance_x ? xInit : xEnd;
  yEnd = distance_y > distance_x ? yEnd : yInit;

  let rotation, translationX, translationY;
  if (isTextOnMiddle) {
    rotation = distance_y > distance_x ? Math.PI / 2 : 0;
    translationX = xInit - 1 + distance_x / partPosition;
    translationY = yInit + distance_y / partPosition;
  } else {
    rotation = xEnd ? Math.atan(distance_y / distance_x) : Math.PI / 2;
    translationX = xInit + distance_x / partPosition;
    translationY = yInit + distance_y / partPosition;
  }

  ctx.font = font;
  ctx.translate(translationX, translationY);
  ctx.rotate(rotation);
  ctx.translate(-translationX, -translationY);
  ctx.fillStyle = '#1a242d';
  ctx.textAlign = 'center';

  ctx.fillText(text, translationX, translationY);
  ctx.restore();
}

export const handleCanvasClickDefault = (event) => {
  console.log('[Default] clickCoords: ', {
    x: event.clientX,
    y: event.clientY,
  });
};

export const handleCanvasHoverDefault = (canvasRef, event) => {
  // Ignore event to avoid annoying console.logs of hover coords
};

var iconoSVG =
  '<?xml version="1.0" ?><svg height="24" version="1.1" width="24" xmlns="http://www.w3.org/2000/svg" xmlns:cc="http://creativecommons.org/ns#" xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"><g transform="translate(0 -1028.4)"><path d="m12 0c-4.4183 2.3685e-15 -8 3.5817-8 8 0 1.421 0.3816 2.75 1.0312 3.906 0.1079 0.192 0.221 0.381 0.3438 0.563l6.625 11.531 6.625-11.531c0.102-0.151 0.19-0.311 0.281-0.469l0.063-0.094c0.649-1.156 1.031-2.485 1.031-3.906 0-4.4183-3.582-8-8-8zm0 4c2.209 0 4 1.7909 4 4 0 2.209-1.791 4-4 4-2.2091 0-4-1.791-4-4 0-2.2091 1.7909-4 4-4z" fill="#e74c3c" transform="translate(0 1028.4)"/><path d="m12 3c-2.7614 0-5 2.2386-5 5 0 2.761 2.2386 5 5 5 2.761 0 5-2.239 5-5 0-2.7614-2.239-5-5-5zm0 2c1.657 0 3 1.3431 3 3s-1.343 3-3 3-3-1.3431-3-3 1.343-3 3-3z" fill="#c0392b" transform="translate(0 1028.4)"/></g></svg>';
