import { getChainsList, getRobotListBaldur, groupedRobotParameters, isLoadingChains, isLoadingGroupedRobotParameters, isLoadingRobotListBaldur, isLoadingRobotParameters, isLoadingRobotParamsPost, parameters, robotParameters, robotParamsPostResponse } from 'core/selectors';
import { getChains, getRobots } from 'core/slices/backend';
import { getGroupedRobotParameters, getParameters, getRobotParameters, postRobotParameters } from 'core/slices/baldur/parameters';
import { getRobotsBaldur } from 'core/slices/fleetControl';
import { setSnackAlert } from 'core/slices/snackAlert';
import { connect } from 'react-redux';

import ParametersHome from "../../../components/parameters/ParametersHome";

export default connect(
  (state) => ({
    chains: getChainsList(state),
    isLoadingChains: isLoadingChains(state),
    robots: getRobotListBaldur(state),
    isLoadingRobots: isLoadingRobotListBaldur(state),
    robotParameters: robotParameters(state),
    isLoadingRobotParameters: isLoadingRobotParameters(state),
    groupedRobotParameters: groupedRobotParameters(state),
    isLoadingGroupedRobotParameters: isLoadingGroupedRobotParameters(state),
    parameters: parameters(state),
    robotParamsPostResponse: robotParamsPostResponse(state),
    isLoadingRobotParamsPost: isLoadingRobotParamsPost(state),
  }),
  {
    getChains,
    getRobotsBaldur,
    getRobotParameters,
    getGroupedRobotParameters,
    getParameters,
    postRobotParameters,
    setSnackAlert,
  }
)(ParametersHome);