import { Box, Grid } from '@mui/material';
import { CategoryBar } from '@tremor/react';
import { Text, Title } from '@zippeditoolsjs/dashboards';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toTitleCase } from '../tools/utils';

export default function CategoryBarChart(props) {
  const {
    size = 6,
    title = 'Active',
    categoryData,
    textOrientation = 'text-left',
  } = props;
  const { t } = useTranslation();
  const [total, setTotal] = useState(null);
  const [statusData, setStatusData] = useState([]);

  useEffect(() => {
    if (categoryData) {
      setTotal(categoryData.total);
      setStatusData(categoryData.data);
    }
  }, [categoryData]);

  return (
    <Grid container item xs={size} padding={1}>
      <Title>{title}</Title>
      {statusData?.length ? (
        statusData.map((element) => {
          return (
            <>
              <Grid item xs={12} justifyContent={'flex-start'}>
                <Box className={textOrientation}>
                  <Text className={textOrientation}>{t(`overseer_app.general.${element.status_id}`, toTitleCase(element.status_id))}
                  </Text>
                </Box>
              </Grid>
              <Grid item xs={12} container alignItems="center">
                <Grid item xs={10}>
                  <CategoryBar
                    values={[element.count, total - element.count]}
                    className="mx-auto max-w-sm"
                    colors={['blue', 'gray']}
                    showLabels={false}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Box display="flex" justifyContent="center">
                    <Text>{element.count}</Text>
                  </Box>
                </Grid>
              </Grid>
            </>
          );
        })
      ) : (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Title>No data</Title>
        </Box>
      )}
    </Grid>
  );
}
