import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  LinearProgress,
  Skeleton,
  Tooltip,
  Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';




import { IconComponent } from '@zippeditoolsjs/zippedi-icons';
import { useContext } from 'react';
import { SchedulerViewContext } from '../../context/SchedulerView';
import Icon from '../tools/Icon';

import VerticalTimeLine from './VerticalTimeLine';


export default function ScheduleTraceability(props) {
  const {
    openModal,
    setOpenModal,
    sessionSelected,
    sessionHistory,
    isLoadingSessionHistory,
    schedulerTimeMode,
  } = props;

  const { t } = useTranslation();

  const {
    userHoursDiff,
  } = useContext(SchedulerViewContext);


  return (
    <>
      <Dialog open={openModal} maxWidth="xl" width='auto' onClose={() => setOpenModal(false)}>
        <DialogTitle sx={{ pb: 1 }}>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <Typography variant="subtitle1" sx={{ display: 'flex', placeItems: 'center' }}>
              <Icon color="blue" iconName='file-tray-full-outline' />
              {t('overseer_app.fleetManagement.Changelog', 'Changelog')}
              <span style={{ marginLeft: '10px' }}>{sessionSelected?.local_start_time}</span>
            </Typography>
            <Tooltip title="Close">
              <IconButton onClick={() => setOpenModal(false)} sx={{ p: 0 }}>
                <IconComponent iconName={'close-circle-outline'} />
              </IconButton>
            </Tooltip>
          </Box>
        </DialogTitle>
        <Divider sx={{ mb: 1 }} />
        <DialogContent sx={{ height: `min(100%, calc(${document.getElementById('layout-map-box')?.clientHeight ?? 400}px + 7em))`, overflowY: 'auto', maxHeight: '45em', overflowX: 'hidden', pb: 0 }}>
          {isLoadingSessionHistory ?
            Array.from(Array(3).keys()).map((_, index) => (
              <Box sx={{ display: 'flex', flexDirection: 'row', mt: index === 0 ? index : 1, placeItems: 'center' }} key={`st-skeleton-${index}`}>
                <Skeleton variant="circular" width={40} height={31} />
                <Skeleton variant="rectangular" width='100%' height='1em' sx={{ ml: 1 }} />
                <Skeleton variant="rectangular" width='10%' height='1em' sx={{ ml: 1 }} />
              </Box>
            ))
            :
            <VerticalTimeLine
              schedulerTimeMode={schedulerTimeMode}
              userHoursDiff={userHoursDiff}
              sessionHistory={sessionHistory}
              sessionSelected={sessionSelected}
            />
          }
        </DialogContent>
        <Divider sx={{ mt: 2 }} />
        <DialogActions sx={{ justifyContent: 'flex-start' }}>
          <Typography variant="caption" sx={{ color: 'grey' }}>
            * The time of each point on this timeline will depend on your selection in time format
          </Typography>
        </DialogActions>
        {isLoadingSessionHistory && <LinearProgress color="secondary" sx={{ width: '100%', borderRadius: '5px' }} />}
      </Dialog >
    </>

  );
}
