import {
  Box,
  Collapse,
  IconButton,
  List,
  Tooltip,
  Typography
} from '@mui/material';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';


import Timeline from '@mui/lab/Timeline';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineSeparator from '@mui/lab/TimelineSeparator';

import { IconComponent } from '@zippeditoolsjs/zippedi-icons';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import NotFound from '../notFound/NotFound';
import { NAVIGATION_STATUS } from '../pipelineMonitoring/Utils';
import SvgIcon from '../utils/SVGIcon';

import { handleSessionScheduleIcon } from './Utils';


export default function VerticalTimeLine(props) {
  const {
    sessionHistory,
    sessionSelected,
    schedulerTimeMode,
    userHoursDiff,
  } = props;
  const [openInfoBox, setOpenInfoBox] = useState(new Array(sessionHistory?.length ?? []).fill(false))

  const handleOpenInfoBox = (index) => {
    const newOpenInfoBox = [...openInfoBox];
    newOpenInfoBox[index] = !newOpenInfoBox[index];
    setOpenInfoBox(newOpenInfoBox);
  }

  return (
    <Timeline>
      {
        sessionHistory?.length > 0 &&
        sessionHistory.map((history, index) => (
          <TimelineItem key={`session-history-${index}`}>
            {/* LEFT SIDE */}
            <TimelineOppositeContent color="text.secondary">
              {dayjs(history?.created_at).add(schedulerTimeMode === 'user_scheduler' ? userHoursDiff : sessionSelected?.utc_offset ?? -3, 'hours').format('MMM D, YYYY')}
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot variant="outlined" color={NAVIGATION_STATUS.CANCELLED === history.status ? 'grey' : 'info'} sx={(theme) => { return { color: NAVIGATION_STATUS.CANCELLED === history.status ? 'grey' : theme.palette.info?.main } }} >
                {
                  history?.user_action === 'create_session' ?
                    <Tooltip title='Session Created'>
                      <Typography>
                        <IconComponent iconName='add' style={{ fontSize: '20px' }} />
                      </Typography>
                    </Tooltip>
                    :
                    index === sessionHistory.length - 1 ?
                      <Tooltip title={`Change to the current session${NAVIGATION_STATUS.CANCELLED === history.status ? ' (Cancelled)' : ''}`}>
                        <Typography>
                          <IconComponent iconName='checkmark' style={{ fontSize: '20px' }} />
                        </Typography>
                      </Tooltip>
                      :
                      <Tooltip title={NAVIGATION_STATUS.CANCELLED === history.status ? 'Cancelled session' : ''}>
                        <Typography>
                          {handleSessionScheduleIcon(history.session_type)}
                        </Typography>
                      </Tooltip>
                }
              </TimelineDot>
              {index !== sessionHistory.length - 1 && <TimelineConnector />}
            </TimelineSeparator>
            {/* RIGHT SIDE */}
            <TimelineContent sx={{ pb: 4 }}>
              <Box sx={{ width: '45em' }}>
                <Box sx={(theme) => { return { padding: '1em', borderRadius: 0, borderBottom: '1px solid #e0e0e0', py: 0, pl: 0 } }}>
                  <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Box sx={{ display: 'inherit', flexDirection: 'inherit', alignItems: 'inherit' }}>
                      {/* PRIMARY  TEXT */}
                      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography variant="small" component="span">
                          {history?.user_action === 'create_session' ? 'Created' : 'Changes'} at <b>{dayjs(history?.created_at).add(
                            schedulerTimeMode === 'user_scheduler' ? userHoursDiff : sessionSelected?.utc_offset ?? -3, 'hours').format('HH:mm')
                          }</b>, by <Typography component='span' sx={(theme) => { return { color: theme.palette.info?.main } }}>{history.username}</Typography>
                        </Typography>
                        {/* SECONDARY TEXT */}
                        <Typography sx={{ display: 'flex', color: 'grey', placeItems: 'center' }}>
                          {history.robot_ack ?
                            <Tooltip title={'Confirmed by robot'}>
                              <Typography><SvgIcon name="overseer-robot-validation" style={{ fontSize: "30px", position: 'relative', left: '0', marginRight: '5px' }} /></Typography>
                            </Tooltip>
                            : index < sessionHistory.length - 1 ?
                              <Tooltip title={'Not confirmed by robot'}>
                                <Typography>
                                  <IconComponent iconName='help-circle-outline' style={{ fontSize: 25, marginRight: '5px' }} />
                                </Typography>
                              </Tooltip>
                              :
                              <Tooltip title={'waiting by robot confirmation'}>
                                <Typography><SvgIcon name="overseer-robot-waiting" style={{ fontSize: "30px", position: 'relative', left: '0', marginRight: '5px' }} /></Typography>
                              </Tooltip>
                          }
                          <Typography component="span" sx={{ fontSize: 'smaller', color: 'grey', borderLeft: '1px solid', pl: '5px' }}>{history?.comments_reason}</Typography>
                        </Typography>
                      </Box>
                    </Box>
                    <Tooltip title={'Show changes'}>
                      <IconButton onClick={() => { handleOpenInfoBox(index) }} rotate={openInfoBox[index] ? 180 : 0} sx={{ transition: 'transform 0.3s', transform: openInfoBox[index] ? 'rotate(180deg)' : 'rotate(0deg)' }}>
                        <IconComponent iconName={'chevron-down-outline'} />
                      </IconButton>
                    </Tooltip>
                  </Box>
                  <Collapse in={openInfoBox[index]}>
                    <List sx={{ overflowY: 'auto', overflowX: 'hidden', maxHeight: '35em', ml: 4, minHeight: 'calc(100% - 2em)' }}>

                      <Box>
                        <ChangeTable changes={history.changes ?? {}}
                          beforeParams={history.beforeParams ?? {}}
                        />
                        {history.comments_comment && <Typography variant="subtitle2" sx={{ mt: 1 }}>Comments: {history.comments_comment}</Typography>}
                      </Box>
                    </List>
                  </Collapse>
                </Box>
              </Box>
            </TimelineContent>
          </TimelineItem>
        ))
      }
    </Timeline>
  );
}

function ChangeTable(props) {
  const {
    changes,
    beforeParams,
  } = props;

  const [rows, setRows] = useState([]);

  useEffect(() => {
    const rows = [];
    let afterEndTime, beforeEndTime;
    for (const key in changes) {
      if (Object.hasOwnProperty.call(changes, key)) {
        const [before, after] = changes[key];
        if (before !== null || after !== null) rows.push({ field: key, before: String(before ?? '-'), after: String(after ?? '-') });
        if (key === 'max_minutes' || key === 'local_start_time') {
          const beforeStartTime = beforeParams?.local_start_time ? dayjs(beforeParams.local_start_time, 'HH:mm') : null
          const beforeMaxMinutes = beforeParams?.max_minutes ?? 0;
          const afterStartTime = changes.local_start_time ? dayjs(changes.local_start_time[1], 'HH:mm') : beforeStartTime;
          const afterMaxMinutes = changes.max_minutes ? changes.max_minutes[1] : beforeMaxMinutes;
          beforeEndTime = beforeStartTime ? beforeStartTime.add(beforeMaxMinutes, 'minutes').format('HH:mm A') : null;
          afterEndTime = afterStartTime ? afterStartTime.add(afterMaxMinutes, 'minutes').format('HH:mm A') : null;
        }
      }
    }
    if (beforeEndTime || afterEndTime) {
      rows.push({ field: 'local_end_time', before: String(beforeEndTime ?? '-'), after: String(afterEndTime ?? '-') });
    }

    setRows(rows);
  }, [changes]);

  return (
    rows.length > 0 ?
      <TableContainer component={Paper} elevation={0} sx={{ maxWidth: '40em', border: '1px solid lightgray' }} >
        <Table sx={{ minWidth: 350 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell><b>Field</b></TableCell>
              <TableCell><b>Before</b></TableCell>
              <TableCell><b>After</b></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.name}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">{row.field}</TableCell>
                {/* BEFORE FIELD */}
                <TableCell>
                  <Box sx={(theme) => {
                    return {
                      minWidth: '7em',
                      backgroundColor: `${theme.palette.error.main}44`,
                      borderRadius: '5px',
                      padding: '0.5em',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                    }
                  }}>
                    <Typography variant="caption" sx={{ borderRight: '1px solid red', px: 1 }}>
                      <IconComponent iconName='remove-circle' color={'red'} style={{ fontSize: 20 }} />
                    </Typography>
                    <Typography variant="caption" sx={{ px: 1 }}>
                      {row.before}
                    </Typography>
                  </Box>
                </TableCell>
                {/* AFTER FIELD */}
                <TableCell>
                  <Box sx={(theme) => {
                    return {
                      minWidth: '7em',
                      backgroundColor: `${theme.palette.teal.main}44`,
                      color: theme.palette.text.primary,
                      borderRadius: '5px',
                      padding: '0.5em',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                    }
                  }}>
                    <Typography variant="caption" sx={{ borderRight: '1px solid teal', px: 1 }}>
                      <IconComponent iconName='add-circle' color={'teal'} style={{ fontSize: 20 }} />
                    </Typography>
                    <Typography variant="caption" sx={{ px: 1 }}>
                      {row.after}
                    </Typography>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      :
      <NotFound
        title='No changes detected'
        content='The parameters of this version are the same as the next one'
        imgProps={{ style: { width: '2em', height: 'auto' } }}
        imgBoxStyles={{ padding: '1em' }}
        titleStyles={{ pt: 0 }}
        contentStyles={{ pt: 0 }}
      ></NotFound>

  );
}
