import { connect } from 'react-redux';

import {
  getCancelMessage,
  getNewSessionResponse,
  getReasonOptions,
  isLoadingActionReasons,
  isLoadingStoresUtcOffset,
  loadingNewSessionResponse,
  storesUtcOffset
} from 'core/selectors';

import { cancelSessions, postNewSessions, removeCancelSessionResponse, removeNewSessionResponse, updateNewSessions } from 'core/slices/baldur/scheduler';
import { getActionReasons } from 'core/slices/robotMonitoring';
import { setSnackAlert } from 'core/slices/snackAlert';
import { getStoresUtcOffset } from 'core/slices/store';

import SessionForm from '../../../components/fleetManagement/forms/SessionForm';

export default connect(
  (state) => ({
    actionReasons: getReasonOptions(state),
    isLoadingActionReasons: isLoadingActionReasons(state),
    newSessionResponse: getNewSessionResponse(state),
    loadingNewSessions: loadingNewSessionResponse(state),
    cancelMessage: getCancelMessage(state),
    storesUtcOffset: storesUtcOffset(state),
    isLoadingStoresUtcOffset: isLoadingStoresUtcOffset(state),
  }),
  {
    getActionReasons,
    postNewSessions,
    updateNewSessions,
    cancelSessions,
    removeNewSessionResponse,
    removeCancelSessionResponse,
    setSnackAlert,
    getStoresUtcOffset,
  }
)(SessionForm);
