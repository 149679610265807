import { LinearProgress } from '@mui/material';
import { Suspense, useEffect, useState } from 'react';
import {
  drawHeatmap,
  drawLaserHits,
  drawPath,
  drawRobotPose,
} from '../utils/canvasFunctions';
import useCanvas from './useCanvas';

export default function MapCanvas(props) {
  const {
    id,
    info,
    width = 600,
    height = 600,
    navInfo,
    isFollowingRobot,
    layersDisplay,
    signalDisplay,
    uniqueLayerDisplay,
    mapSignal,
    mappingAlerts,
    mapControlsRef,
    onCanvasListChange = () => { },
    robotPosition,
  } = props;

  const [allCanvas, setAllCanvas] = useState([]);

  const [canvasRef, canvasDraw] = useCanvas();
  const [canvasLaserRef, canvasLaserDraw] = useCanvas();
  const [canvasLocalNavRef, canvasLocalNavDraw] = useCanvas();
  const [canvasGlobalNavRef, canvasGlobalNavDraw] = useCanvas();
  const [canvasSignalRef, canvasSignalDraw] = useCanvas();
  const [canvasMappingAlertRef, canvasMappingAlertDraw] = useCanvas();
  const [canvasRobotRef, canvasRobotDraw] = useCanvas();

  useEffect(() => {
    const canvas = document.getElementById(`map-canvas-${id}`);
    const laserCanvas = document.getElementById(`mini-canvas-laser-${id}`);
    const localNavCanvas = document.getElementById(`mini-canvas-local-${id}`);
    const globalNavCanvas = document.getElementById(`mini-canvas-global-${id}`);
    const heatmapCanvas = document.getElementById(`heatmap-canvas-${id}`);
    const mappingAlertCanvas = document.getElementById(`mapping-alert-canvas-${id}`);
    const robotCanvas = document.getElementById(`mini-canvas-robot-${id}`);
    const canvasArray = [canvas, laserCanvas, localNavCanvas, globalNavCanvas, robotCanvas];

    // add canvas to the list of canvases to allow the download of the map
    const canvasIds = [`map-canvas-${id}`, `mini-canvas-laser-${id}`, `mini-canvas-local-${id}`, `mini-canvas-global-${id}`, `heatmap-canvas-${id}`, `mapping-alert-canvas-${id}`, `mini-canvas-robot-${id}`]
    onCanvasListChange(canvasIds);

    setAllCanvas(canvasArray);
    canvasArray.forEach((cv) => {
      if (cv) {
        cv.width = width;
        cv.height = height;
      }
    });

    handleCanvasSize({ targetCanvas: heatmapCanvas, height, width });
    handleCanvasSize({ targetCanvas: mappingAlertCanvas, height, width });

    const canvasIdSet = new Set([...(mapControlsRef.current?.canvasIdSet || []), ...canvasIds]);
    mapControlsRef.current = {
      ...mapControlsRef.current,
      canvasIdSet,
      handleCleanCanvas,
    };
  }, [width, height]);

  useEffect(() => {
    allCanvas.forEach((cv) => {
      cv.getContext('2d').clearRect(0, 0, cv.width, cv.height);
    });
  }, [layersDisplay]);

  useEffect(() => {
    const cv = document.getElementById(`heatmap-canvas-${id}`);
    cv.getContext('2d').clearRect(0, 0, cv.width, cv.height);
  }, [signalDisplay]);

  useEffect(() => {
    if (mapSignal && signalDisplay) {
      canvasSignalDraw(drawHeatmap, [mapSignal]);
    }
  }, [mapSignal]);

  useEffect(() => {
    if (uniqueLayerDisplay === 'mapping_alert' && mappingAlerts) {
      canvasMappingAlertDraw(drawHeatmap, [mappingAlerts]);
    }
  }, [mappingAlerts, uniqueLayerDisplay]);

  useEffect(() => {
    if (info.img && canvasRef?.current && navInfo.pose) {
      if (robotPosition && robotPosition.follow_pose === undefined) {
        robotPosition.follow_pose = true;
      } else if (robotPosition && robotPosition.follow_pose === true) {
        robotPosition.follow_pose = false;
      }
      handleLayers();
    }
  }, [info, navInfo]);

  const handleCleanCanvas = ({ canRemove }) => {
    const toRemoveCanvas = canRemove ? (mapControlsRef.current?.canvasIdSet || []) : [];
    toRemoveCanvas.forEach((canvasId) => {
      const cv = document.getElementById(canvasId);
      if (cv) cv.getContext('2d').clearRect(0, 0, cv.width, cv.height)
    })
  };

  const handleLayers = () => {
    layersDisplay.forEach((layer) => {
      if (layer === 'robot') {
        canvasDraw(drawRobotPose, [
          navInfo.pose,
          info,
          isFollowingRobot,
          `map-canvas-${id}`,
        ]);
        if (robotPosition?.x_pose) {
          canvasRobotDraw(drawRobotPose, [
            [robotPosition.x_pose, robotPosition.y_pose, -1.59],
            info,
            robotPosition.follow_pose,
            `map-canvas-${id}`,
            'RobotSvg',
          ]);
        }
      } else if (layer === 'lidar')
        canvasLaserDraw(drawLaserHits, [navInfo.laser_hits, info]);
      else if (layer === 'navigation_path') {
        canvasLocalNavDraw(drawPath, [navInfo.local_nav_points, info, 'green']);
        canvasGlobalNavDraw(drawPath, [
          navInfo.global_nav_points,
          info,
          'blue',
        ]);
      }
    });
  };

  const handleCanvasSize = ({ targetCanvas, height, width }) => {
    targetCanvas.width = width;
    targetCanvas.height = height;
  }

  return (
    <>
      <Suspense
        fallback={
          <LinearProgress sx={{ width: '98%', mx: 'auto' }} color="secondary" />
        }
      >
        <canvas
          id={`map-canvas-${id}`}
          ref={canvasRef}
          style={{
            borderRadius: '5px',
            outline: 'none',
            width: 'auto',
            position: 'absolute',
            left: 0,
            top: 0,
            zIndex: 2,
          }}
        />
        <canvas
          id={`mini-canvas-robot-${id}`}
          ref={canvasRobotRef}
          style={{
            borderRadius: '5px',
            outline: 'none',
            width: 'auto',
            position: 'absolute',
            left: 0,
            top: 0,
            zIndex: 2,
          }}
        />
        <canvas
          id={`mini-canvas-laser-${id}`}
          ref={canvasLaserRef}
          style={{
            borderRadius: '5px',
            outline: 'none',
            width: 'auto',
            position: 'absolute',
            left: 0,
            top: 0,
            zIndex: 2,
          }}
        />
        <canvas
          id={`mini-canvas-local-${id}`}
          ref={canvasLocalNavRef}
          style={{
            borderRadius: '5px',
            outline: 'none',
            width: 'auto',
            position: 'absolute',
            left: 0,
            top: 0,
            zIndex: 2,
          }}
        />
        <canvas
          id={`mini-canvas-global-${id}`}
          ref={canvasGlobalNavRef}
          style={{
            borderRadius: '5px',
            outline: 'none',
            width: 'auto',
            position: 'absolute',
            left: 0,
            top: 0,
            zIndex: 2,
          }}
        />
        <canvas
          id={`heatmap-canvas-${id}`}
          ref={canvasSignalRef}
          style={{
            borderRadius: '5px',
            outline: 'none',
            width: 'auto',
            position: 'absolute',
            left: 0,
            top: 0,
            zIndex: 2,
          }}
        />
        <canvas
          id={`mapping-alert-canvas-${id}`}
          ref={canvasMappingAlertRef}
          style={{
            borderRadius: '5px',
            outline: 'none',
            width: 'auto',
            position: 'absolute',
            left: 0,
            top: 0,
            zIndex: 2,
          }}
        />
      </Suspense>
    </>
  );
}
