import React, { useState, useEffect, useRef } from 'react';
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  Grid,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Typography,
  Autocomplete,
  Tooltip
} from '@mui/material';
import { Map, Marker, useMapsLibrary } from '@vis.gl/react-google-maps';
import { useTranslation } from 'react-i18next';
import InfoIcon from '@mui/icons-material/Info';

import AutocompleteCustom from './AutocompleteCustom';

export default function StoreFormDialog(props) {
  const { open, action, onClose, onSave, chainsList, countries, storeInfo, setSnackAlert } =
    props;

  const [storeCode, setStoreCode] = useState(null);
  const [coordinates, setCoordinates] = useState(null);
  const [storeConfigCopy, setStoreConfigCopy] = useState(null);
  const [geocodingService, setGeocodingService] = useState(null);
  const [currentCenter, setCurrentCenter] = useState(null);
  const [currentZoom, setCurrentZoom] = useState(null);

  const mapRef = useRef(null);
  const geocoding = useMapsLibrary('geocoding');

  const { t } = useTranslation();

  const handleMapLoad = (map) => {
    mapRef.current = map;
  };

  const [storeData, setStoreData] = useState({
    chain: '',
    code: '',
    name: '',
    type: '',
    address: '',
    country_name: '',
    coordinates: { lat: null, lng: null },
  });

  useEffect(() => {
    if (storeInfo) {
      const initialStoreData = {
        chain: storeInfo.chain_name,
        code: storeInfo.store_code,
        name: storeInfo.store_name,
        type: storeInfo.store_type,
        address: storeInfo.address,
        country_name: storeInfo.country_name,
        coordinates: storeInfo.coordinates,
      };
      setStoreData(initialStoreData);
      setStoreConfigCopy(initialStoreData);
      setStoreCode(storeInfo.store_code);
      setCurrentCenter(storeInfo.coordinates);
      setCurrentZoom(15);
    }
  }, [storeInfo]);

  useEffect(() => {
    if(geocoding) {
      setGeocodingService(new geocoding.Geocoder())
    }
  }, [geocoding]);

  useEffect(() => {
    if (action === 'create') {
      refreshStoreData();
    }
  }, [open]);

  const checkChanges = (initialObj, updatedObj) => {
    const changes = {};
    Object.keys(updatedObj).forEach((key) => {
      if (initialObj[key] !== updatedObj[key]) {
        changes[key] = updatedObj[key];
      }
    });
    return changes;
  };

  const handleSave = () => {
    if (!validateForm()) {
      if (action === 'edit') {
        let data = checkChanges(storeConfigCopy, storeData);
        onSave(data, action, storeCode);
      } else onSave(storeData, action);
      onClose();
    } else console.log('error');
  };

  const handlePlaceSelect = (place) => {
    if (place && place.geometry) {
      let countryCode = place?.address_components?.find((component) =>
        component.types.includes('country')
      )?.short_name;
      let countryName = countries?.find(
        (country) => country.code === countryCode
      )?.name;
      setStoreData({
        ...storeData,
        address: place.formatted_address,
        country_name: countryName,
        coordinates: {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        },
      });
      setCoordinates({
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      });
      setCurrentCenter({
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      })
      setCurrentZoom(15)
    }
  };

  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.panTo(coordinates);
    }
  }, [coordinates]);

  const handleChainChange = (event, newChain) => {
    setStoreData({ ...storeData, chain: newChain });
  };

  const handleCodeChange = (event) => {
    let numericPart = event.target.value.replace(/\D/g, '');
    numericPart = numericPart.substring(0, 4);
    let acronym = chainsList.find(
      (chain) => chain.name === storeData.chain
    )?.acronym;
    const store_code = `${acronym}${numericPart}`;
    setStoreData({ ...storeData, code: store_code });
  };

  const handleStoreType = (event) => {
    setStoreData({ ...storeData, type: event.target.value });
  };

  const handleStoreName = (event) => {
    setStoreData({ ...storeData, name: event.target.value });
  };

  const refreshStoreData = () => {
    setStoreData({
      chain: '',
      code: '',
      name: '',
      type: '',
      address: '',
      country_name: '',
      coordinates: { lat: null, lng: null },
    });
  };

  const validateForm = () => {
    return Object.keys(storeData).some((key) => storeData[key] === '');
  };

  const onMapClick = (parameter) => {
    const {lat, lng} = parameter.detail.latLng;
    const location = { lat, lng };

    // Fetch address by coordinates
    geocodingService.geocode({ location }, (results, status) => {
      if (status === "OK") {
        if (results[0]) {
          // Get country name
          let countryCode = results[0].address_components?.find((component) =>
            component.types.includes('country')
          )?.short_name;
          let countryName = countries?.find(
            (country) => country.code === countryCode
          )?.name;
          // Store map data
          setStoreData({
            ...storeData,
            address: results[0].formatted_address,
            country_name: countryName,
            coordinates: {
              lat: lat,
              lng: lng,
            },
          });
          setCoordinates({
            lat: lat,
            lng: lng,
          });
          setCurrentCenter({
            lat: lat,
            lng: lng,
          })
          setCurrentZoom(15)
        } else {
          setSnackAlert({
            open: true,
            message: t('overseer_app.general.no_results', 'No results found'),
            severity: 'error',
          })
        }
      } else {
        setSnackAlert({
          open: true,
          message: t('overseer_app.general.geocoding_error', 'Geocoding error'),
          severity: 'error',
        })
      }

    });
  }

  const onCameraChanged = (element) => {
    setCurrentCenter(null)
    setCurrentZoom(null)
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>Create Store</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} paddingTop={1}>
          <Grid item xs={6}>
              <Autocomplete
              fullWidth
              value={storeData.chain}
              options={chainsList.map((option) => option.name)}
              onChange={handleChainChange}
              renderInput={(params) => <TextField {...params} label="Chains" />}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Code"
              required
              fullWidth
              value={storeData.code}
              onChange={handleCodeChange}
              disabled={action === 'create' ? false : true}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Name"
              required
              fullWidth
              value={storeData.name}
              onChange={handleStoreName}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Store type"
              required
              select
              fullWidth
              value={storeData.type}
              onChange={handleStoreType}
            >
              <MenuItem value="production">Production</MenuItem>
              <MenuItem value="test">Test</MenuItem>
              <MenuItem value="staging">Staging</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6">
              Find exact address 
              <Tooltip 
                title={t('overseer_app.general.map_search', 'You can either search the desired place by address or clicking the map')}
                sx={{marginLeft: "0.2rem"}}
                placement="right-start"
              >
                <InfoIcon fontSize="small" />
              </Tooltip>
              </Typography>
            <AutocompleteCustom onPlaceSelect={handlePlaceSelect} />
          </Grid>
          <Grid item xs={12} style={{ height: '300px' }}>
            <Map
              onLoad={handleMapLoad}
              ref={mapRef}
              mapContainerStyle={{ height: '100%', width: '100%' }}
              defaultCenter={{ lat: -33.45, lng: -70.65 }}
              defaultZoom={storeData.coordinates.lat ? 14 : 5}
              center={currentCenter}
              zoom={currentZoom}
              onClick={onMapClick}
              onCameraChanged={onCameraChanged}
            >
              {storeData?.coordinates && (
                <Marker position={storeData?.coordinates} />
              )}
            </Map>
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Address"
              fullWidth
              value={storeData.address}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Coordinates"
              fullWidth
              value={
                storeData.coordinates.lat
                  ? `Lat: ${storeData.coordinates.lat}, Lng: ${storeData.coordinates.lng}`
                  : ''
              }
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button
          onClick={handleSave}
          disabled={validateForm()}
          variant="contained"
          color="primary"
        >
          {action === 'create' ? 'Create store' : 'Save'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
