import {
  handleRequest,
  handleAvailable,
  handleUnavailable,
} from '../reducerHandlers';
import {
  SNACK_SET,
  STORE_CONFIG_REQUEST,
  STORE_CONFIG_AVAILABLE,
  STORE_CONFIG_UNAVAILABLE,
} from './constants';
import { createReducer } from './utils';

const defaultState = {
  storeConfigs: [],
  isLoadingStoreConfigs: false,
  newStoreConfigResponse: '',
  isLoadingNewStoreConfig: false,
  chainsColor: {},
  isLoadingChainsColor: false,
};

export const reducer = createReducer(defaultState, {
  [STORE_CONFIG_REQUEST]: handleRequest,
  [STORE_CONFIG_AVAILABLE]: handleAvailable,
  [STORE_CONFIG_UNAVAILABLE]: handleUnavailable,
});

export function getStoreConfigs() {
  return async (dispatch, _, { services: { dataSource } }) => {
    const keyState = 'storeConfigs';
    const loadingName = 'isLoadingStoreConfigs';
    dispatch({
      type: STORE_CONFIG_REQUEST,
      payload: { loadingName: loadingName },
    });
    try {
      const response = await dataSource.getStoreConfigs();
      dispatch({
        type: STORE_CONFIG_AVAILABLE,
        payload: {
          keyState: keyState,
          data: response,
          loadingName: loadingName,
        },
      });
    } catch (error) {
      console.log('error: ', error);
      dispatch({
        type: STORE_CONFIG_UNAVAILABLE,
        payload: {
          keyState: keyState,
          loadingName: loadingName,
          defaultState: [],
        },
      });
      const snack = {
        open: true,
        message: 'There was an error getting the store configs.',
        severity: 'error',
      };
      dispatch({ type: SNACK_SET, payload: { snack } });
    }
  };
}

export function postStoreConfig(storeData) {
  return async (dispatch, _, { services: { dataSource } }) => {
    const keyState = 'newStoreConfigResponse';
    const loadingName = 'isLoadingNewStoreConfig';
    let snack;
    dispatch({
      type: STORE_CONFIG_REQUEST,
      payload: { loadingName: loadingName },
    });
    try {
      const response = await dataSource.postNewStoreConfig(storeData);
      dispatch({
        type: STORE_CONFIG_AVAILABLE,
        payload: {
          keyState: keyState,
          data: response,
          loadingName: loadingName,
        },
      });
      snack = {
        open: true,
        message: response.message,
        severity: 'success',
      };
    } catch (error) {
      console.log('error: ', error);
      dispatch({
        type: STORE_CONFIG_UNAVAILABLE,
        payload: {
          keyState: keyState,
          loadingName: loadingName,
          defaultState: [],
        },
      });
      snack = {
        open: true,
        message: 'There was an error creating the store.',
        severity: 'error',
      };
    }
    dispatch({ type: SNACK_SET, payload: { snack } });
  };
}

export function patchStoreConfig(code, changes) {
  return async (dispatch, _, { services: { dataSource } }) => {
    const keyState = 'storeConfigUpdateResponse';
    const loadingName = 'updatingStoreConfig';
    let snack;
    dispatch({
      type: STORE_CONFIG_REQUEST,
      payload: { loadingName: loadingName },
    });
    try {
      const response = await dataSource.updateStoreConfig(code, changes);
      dispatch({
        type: STORE_CONFIG_AVAILABLE,
        payload: {
          keyState: keyState,
          data: response,
          loadingName: loadingName,
        },
      });
      snack = {
        open: true,
        message: response.message,
        severity: 'success',
      };
    } catch (error) {
      console.log('error: ', error);
      dispatch({
        type: STORE_CONFIG_UNAVAILABLE,
        payload: {
          keyState: keyState,
          loadingName: loadingName,
          defaultState: [],
        },
      });
      snack = {
        open: true,
        message: 'There was an error creating the store.',
        severity: 'error',
      };
    }
    dispatch({ type: SNACK_SET, payload: { snack } });
  };
}

export function deleteStoreConfig(store_code) {
  return async (dispatch, _, { services: { dataSource } }) => {
    const loadingName = 'isDeletingStore';
    const keyState = 'deleteStoreResponse';
    let snack;
    dispatch({ type: STORE_CONFIG_REQUEST, payload: { loadingName } });
    try {
      const storeDeleteResponse = await dataSource.deleteStore(store_code);
      const storeResponse = storeDeleteResponse ? storeDeleteResponse : {};

      dispatch({
        type: STORE_CONFIG_AVAILABLE,
        payload: { keyState, loadingName, data: storeResponse },
      });
      snack = {
        open: true,
        message: 'Store was deleted',
        severity: 'success',
      };
    } catch (error) {
      dispatch({
        type: STORE_CONFIG_UNAVAILABLE,
        payload: { keyState, loadingName },
      });
      snack = {
        open: true,
        message: error.messageParsed.message,
        severity: 'error',
      };
    }
    dispatch({ type: SNACK_SET, payload: { snack } });
  };
}

export function getCountries() {
  return async (dispatch, _, { services: { dataSource } }) => {
    const keyState = 'countries';
    const loadingName = 'isLoadingCountries';
    dispatch({
      type: STORE_CONFIG_REQUEST,
      payload: { loadingName: loadingName },
    });
    try {
      const response = await dataSource.getCountries();
      dispatch({
        type: STORE_CONFIG_AVAILABLE,
        payload: {
          keyState: keyState,
          data: response,
          loadingName: loadingName,
        },
      });
    } catch (error) {
      console.log('error: ', error);
      dispatch({
        type: STORE_CONFIG_UNAVAILABLE,
        payload: {
          keyState: keyState,
          loadingName: loadingName,
          defaultState: [],
        },
      });
      const snack = {
        open: true,
        message: 'There was an error getting the countries.',
        severity: 'error',
      };
      dispatch({ type: SNACK_SET, payload: { snack } });
    }
  };
}

export function getMapsKey() {
  return async (dispatch, _, { services: { dataSource } }) => {
    const keyState = 'gmapsApiKey';
    const loadingName = 'isLoadingGmapsApiKey';
    dispatch({
      type: STORE_CONFIG_REQUEST,
      payload: { loadingName: loadingName },
    });
    try {
      const response = await dataSource.getMapsKey();
      dispatch({
        type: STORE_CONFIG_AVAILABLE,
        payload: {
          keyState: keyState,
          data: response,
          loadingName: loadingName,
        },
      });
    } catch (error) {
      console.log('error: ', error);
      dispatch({
        type: STORE_CONFIG_UNAVAILABLE,
        payload: {
          keyState: keyState,
          loadingName: loadingName,
          defaultState: [],
        },
      });
      const snack = {
        open: true,
        message: 'There was an error getting the maps api key.',
        severity: 'error',
      };
      dispatch({ type: SNACK_SET, payload: { snack } });
    }
  };
}
