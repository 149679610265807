import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useMap, useMapsLibrary } from '@vis.gl/react-google-maps';
import { Autocomplete, TextField } from '@mui/material';
import {debounce} from '../utils/debounce'

export default function AutocompleteCustomHybrid(props) {
  const { onPlaceSelect } = props;
  const map = useMap();
  const places = useMapsLibrary('places');

  const [sessionToken, setSessionToken] = useState(null);
  const [autocompleteService, setAutocompleteService] = useState(null);
  const [placesService, setPlacesService] = useState(null);
  const [predictionResults, setPredictionResults] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [fetchingData, setFetchingData] = useState(false);
  const [fetchingDetails, setFetchingDetails] = useState(false);

  useEffect(() => {
    if (!places || !map) return;

    setAutocompleteService(new places.AutocompleteService());
    setPlacesService(new places.PlacesService(map));
    setSessionToken(new places.AutocompleteSessionToken());

    return () => setAutocompleteService(null);
  }, [map, places]);


  const fetchPredictions = useCallback(
    (inputValue) => {
      if (!autocompleteService || !inputValue ) return;

      setFetchingData(true);

      autocompleteService.getPlacePredictions(
        { input: inputValue, sessionToken },
        (predictions) => {
          setPredictionResults(predictions || []);
          setFetchingData(false);
        }
      );
    },
    [autocompleteService, sessionToken]
  );

  // Debouced version of fetchPredictions
  const debouncedFetchPredictions = useMemo(() => debounce(fetchPredictions, 500), [fetchPredictions]);

  const handleInputChange = (event, value) => {
    setInputValue(value);
    if(event?.type === 'change') {
      debouncedFetchPredictions(value);
    }
  };

  const handleSelect = (event, value) => {
    if (!value || !places) return;

    setFetchingDetails(true);

    const detailRequestOptions = {
      placeId: value.place_id,
      fields: ['geometry', 'name', 'formatted_address', 'address_components'],
      sessionToken,
    };

    placesService.getDetails(detailRequestOptions, (placeDetails) => {
      onPlaceSelect(placeDetails);
      setSessionToken(new places.AutocompleteSessionToken());
      setFetchingDetails(false);
    });
  };
  
  return (
    <Autocomplete
      options={predictionResults}
      getOptionLabel={(option) => option.description || ''}
      isOptionEqualToValue={(option, value) => {
        return option.place_id === value.place_id;
      }}
      onInputChange={handleInputChange}
      onChange={handleSelect}
      inputValue={inputValue}
      loading={fetchingData}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Search Address"
          variant="outlined"
          fullWidth
        />
      )}
    />
  );
}
