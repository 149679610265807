export default function DataSourceService({ baseUrl, http, ...rest }) {
  const PATH_RETRIEVE_SCHEDULER = '/v1/retrieve_scheduler';
  const PATH_SESSIONS = '/v1/sessions';
  const PATH_SESSIONS_HISTORY = '/v1/sessions/history';
  const PATH_PARAMETERS = '/v1/parameters'
  const PATH_ROBOT_PARAMETERS = '/v1/robot_parameters'
  const PATH_GROUPED_ROBOT_PARAMETERS = '/v1/robot_parameters/group_by_parameter'

  return {
    postRetrieveSessions,
    postNewSessions,
    updateNewSessions,
    cancelSessions,
    getSessionHistory,
    // Parameters
    getParameters,
    // Robot Parameters
    getRobotParameters,
    postRobotParameters,
    getGroupedRobotParameters,
  };

  function postRetrieveSessions(payload) {
    return http.postAuthenticated({
      url: `${baseUrl}${PATH_RETRIEVE_SCHEDULER}`,
      body: payload
    });
  }

  function postNewSessions(sessions) {
    return http.postAuthenticated({
      url: `${baseUrl}${PATH_SESSIONS}`,
      body: sessions
    });
  }

  function updateNewSessions(sessions) {
    return http.patchAuthenticated({
      url: `${baseUrl}${PATH_SESSIONS}`,
      body: sessions
    });
  }

  function cancelSessions(sessions) {
    return http.deleteAuthenticated({
      url: `${baseUrl}${PATH_SESSIONS}`,
      body: sessions
    });
  }

  function getSessionHistory(session_id) {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_SESSIONS_HISTORY}`,
      query: {
        session_id
      }
    });
  }

  // Parameters
  function getParameters() {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_PARAMETERS}`,
    });
  }

  // Robot Parameters
  function getRobotParameters(robot_uuids, only_latest) {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_ROBOT_PARAMETERS}`,
      query: { robot_uuids, only_latest },
    });
  }

  function postRobotParameters(parameters) {
    return http.postAuthenticated({
      url: `${baseUrl}${PATH_ROBOT_PARAMETERS}`,
      body: parameters
    });
  }

  function getGroupedRobotParameters(robot_uuids, only_latest) {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_GROUPED_ROBOT_PARAMETERS}`,
      query: { robot_uuids, only_latest },
    });
  }
}
