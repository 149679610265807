import {
  Badge,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';

//Icons
import { IconComponent } from '@zippeditoolsjs/zippedi-icons';
import { useTranslation } from 'react-i18next';
import { TIERS } from '../tools/constants';
import EscalationClickUpDialog from './EscalationClickUPDialog';
import EscalationDialog from './EscalationDialog';

const { REACT_APP_BASE_URL } = process.env;

// Styled components
const ColorButton = styled(Button)(({ theme }) => ({
  color: 'primary',
  height: theme.mixins.toolbar.minHeight,
}));

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    backgroundColor:
      theme.palette.type === 'light'
        ? theme.palette.primary.main
        : theme.palette.paper.main,
    borderRadius: 3,
    marginTop: theme.spacing(1),
    minWidth: 300,
    maxWidth: 400,
    color: 'white',
  },
}));

const styles = {
  icon: {
    color: 'white',
    fontSize: 30,
  },
  text: {
    fontSize: 16,
    mx: 1,
    mt: 1,
  },
  textUnderline: {
    fontSize: 16,
    mx: 1,
    mt: 1,
    textDecoration: 'underline',
  },
  loadingAvatar: {
    position: 'absolute',
    top: 1,
    left: 4,
    zIndex: -1,
  },
  gridBox: {
    maxHeight: 350,
    maxWidth: 350,
    overflowX: 'hidden',
    overflowY: 'auto',
  },
};

export default function Notification(props) {
  const {
    notificationList,
    isLoadingNotifications,
    updateEventStatus,
    isLoadingUpdateEventStatus,
    getEvents,
    userTier,
    postClickUpTask,
    newClickUpTaskResponse,
    isLoadingClickUpTask,
    clickupTeamsList,
  } = props;

  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [notificationLength, setNotificationLength] = useState(0);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [eventButtonIndex, setEventButtonIndex] = useState(null);
  const [eventAction, setEventAction] = useState(null);
  const [isEscalate, setIsEscalate] = useState(null);
  const [openClickUpEscalation, setOpenClickUpEscalation] = useState(false);
  const [comments, setComments] = useState(null);
  const [selectedNotification, setSelectedNotification] = useState(null);

  const [sortedNotificationList, setSortedNotificationList] = useState([]);

  useEffect(() => {
    if (notificationList?.length > 0) {
      let sortedList = notificationList.sort((a, b) => {
        if (a.notification_id > b.notification_id) return -1;
        return 1;
      });
      setSortedNotificationList(sortedList);

      let newLength = 0;
      notificationList.forEach((notification) => {
        if (!notification.seen_timestamp) {
          newLength++;
        }
      });
      setNotificationLength(newLength);
    } else setNotificationLength(notificationList.length);
  }, [notificationList]);

  useEffect(() => {
    if (!isLoadingClickUpTask) {
      setOpenClickUpEscalation(false);
      getEvents();
    }
  }, [newClickUpTaskResponse, isLoadingClickUpTask]);

  const handleOpenMenu = (event) => {
    setOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(false);
  };

  const handleSelectNotification = (notification) => {
    const robotId = notification.robot_id;
    const [store, robotNumber] = robotId.split('-');
    let url = REACT_APP_BASE_URL + `/robot/${store}/${robotNumber}`;
    window.open(url, '_blank', 'noreferrer');
  };

  const handleButtonClick = (event, notification, index, action) => {
    event.stopPropagation();
    setSelectedEvent(notification.robot_event_id);
    setEventButtonIndex(index);
    setEventAction(action);
    setIsEscalate(userTier >= TIERS.MONITOR_ID ? false : true);
    setOpenDialog(true);
  };

  const handleConfirmResolve = () => {
    if (eventAction === 'change_tier') {
      updateEventStatus(selectedEvent, 'open', isEscalate);
    } else {
      updateEventStatus(selectedEvent, 'resolved');
    }
    setTimeout(() => {
      getEvents();
    }, 4500);
    handleCloseDialog();
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleClickUpEscalate = (payload) => {
    const [store, robotNumber] = selectedNotification.robot_id.split('-');
    const robotUrl = REACT_APP_BASE_URL + `/robot/${store}/${robotNumber}`;
    const {
      robotEventId,
      destinationTeam,
      comment,
      storeCode,
      storeName,
      chainName,
      robotCode,
      alerts,
      mainAlert,
    } = payload;
    postClickUpTask(
      robotEventId,
      destinationTeam,
      comment,
      storeCode,
      storeName,
      chainName,
      robotCode,
      alerts,
      mainAlert,
      robotUrl
    );
  };

  return (
    <div>
      <Box sx={{ m: 1, position: 'relative' }}>
        <ColorButton onClick={handleOpenMenu}>
          <Badge
            badgeContent={notificationLength > 0 ? notificationLength : 0}
            color="error"
          >
            <IconComponent
              iconName={'notifications-outline'}
              style={styles.icon}
            />
          </Badge>
        </ColorButton>
        {isLoadingNotifications && (
          <CircularProgress
            size={55}
            color="secondary"
            style={styles.loadingAvatar}
          />
        )}
      </Box>
      <StyledMenu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {notificationList.length ? (
          sortedNotificationList.map((notification, index) => (
            <div key={`${notification.notification_id}-${index}`}>
              <MenuItem
                onClick={(e) => {
                  handleSelectNotification(notification);
                }}
              >
                <IconComponent
                  iconName={'flame-outline'}
                  style={{ fontSize: '3rem' }}
                />
                <Grid container>
                  <Grid item xs={12}>
                    <Typography sx={styles.textUnderline}>
                      {notification.title}
                    </Typography>
                    <Typography variant="caption" marginX={1} marginTop={0}>
                      {`${notification.alerts_count} active alerts`}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  style={{
                    position: 'relative',
                    display: 'inline-block',
                  }}
                >
                  <Button
                    size="small"
                    onClick={(event) =>
                      handleButtonClick(event, notification, index, 'resolve')
                    }
                    variant={
                      isLoadingUpdateEventStatus ? 'contained' : 'outlined'
                    }
                    color="success"
                    style={{
                      textTransform: 'none',
                      minHeight: '2.5em',
                      marginRight: '0.5em',
                    }}
                    disabled={isLoadingUpdateEventStatus}
                  >
                    {!isLoadingUpdateEventStatus &&
                      t('overseer_app.event.resolve', 'Resolve')}
                    {isLoadingUpdateEventStatus &&
                      index === eventButtonIndex && (
                        <CircularProgress size={20} color="secondary" />
                      )}
                  </Button>
                  {
                    userTier !== TIERS.MONITOR_ID &&
                    <Button
                      size="small"
                      onClick={(event) =>
                        handleButtonClick(
                          event,
                          notification,
                          index,
                          'change_tier'
                        )
                      }
                      variant={
                        isLoadingUpdateEventStatus ? 'contained' : 'outlined'
                      }
                      color="error"
                      style={{
                        textTransform: 'none',
                        minHeight: '2.5em',
                        marginRight: '0.5em',
                      }}
                      disabled={isLoadingUpdateEventStatus}
                    >
                      {!isLoadingUpdateEventStatus ? (
                        userTier > TIERS.MONITOR_ID ? (
                          <>
                            <IconComponent
                              iconName={'arrow-down-sharp'}
                              style={{ fontSize: 18 }}
                            />
                            {t('overseer_app.event.descalate', 'De-escalate')}
                          </>
                        ) : (
                          <>
                            <IconComponent
                              iconName={'arrow-up-sharp'}
                              style={{ fontSize: 18 }}
                            />
                            {t('overseer_app.event.escalate', 'Escalate')}
                          </>
                        )
                      ) : null}
                      {isLoadingUpdateEventStatus &&
                        index === eventButtonIndex && (
                          <CircularProgress size={20} color="secondary" />
                        )}
                    </Button>
                  }
                  {userTier >= TIERS.MONITOR_ID && (
                    <Button
                      size="small"
                      onClick={(event) => {
                        event.stopPropagation();
                        setOpenClickUpEscalation(true);
                        setSelectedNotification(notification);
                      }}
                      variant={
                        isLoadingUpdateEventStatus ? 'contained' : 'outlined'
                      }
                      color="error"
                      style={{ textTransform: 'none', minHeight: '2.5em' }}
                      disabled={isLoadingUpdateEventStatus}
                    >
                      <IconComponent
                        iconName={'arrow-up-sharp'}
                        style={{ fontSize: 18 }}
                      />
                      {t('overseer_app.event.to_clickup', 'ClickUp ')}
                    </Button>
                  )}
                </Grid>
              </MenuItem>
              {notificationList?.length > 1 ? (
                <Divider variant="middle" sx={{ background: '#cacadd4f' }} />
              ) : null}
            </div>
          ))
        ) : (
          <div>
            <MenuItem>
              <Typography sx={styles.text}>
                {t(
                  'overseer_app.home.notification_default',
                  'No new notifications'
                )}
              </Typography>
            </MenuItem>
          </div>
        )}
      </StyledMenu>
      <EscalationDialog
        openDialog={openDialog}
        handleConfirmResolve={handleConfirmResolve}
        eventAction={eventAction}
        userTier={userTier}
        handleCloseDialog={handleCloseDialog}
      />
      <EscalationClickUpDialog
        open={openClickUpEscalation}
        setOpen={setOpenClickUpEscalation}
        comments={comments}
        setComments={setComments}
        handleEscalate={handleClickUpEscalate}
        notification={selectedNotification}
        isLoading={isLoadingClickUpTask}
        clickupTeamsList={clickupTeamsList}
      />
    </div>
  );
}
