import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { titleCase } from '../pipelineMonitoring/Utils';
import Selector from '../tools/Selector';

export default function NewStateDialog(props) {
  const {
    robotState,
    open,
    setOpenState,
    updateRobotState,
    robotCode,
    resetForm,
    setResetForm,
    availableStatus,
    getAvailableRobotStatus,
    loadingNewRobotState,
    robotStateMotives,
  } = props;

  const { t } = useTranslation();

  const [newState, setNewState] = useState(null);
  const [selectedMotive, setSelectedMotive] = useState(null);
  const [otherMotive, setOtherMotive] = useState('');
  const [motive, setMotive] = useState(null);
  const [allStatusFormated, setAllStatusFormated] = useState([]);
  const [statusMotive, setStatusMotive] = useState({});
  const [comment, setComment] = useState('');

  useEffect(() => {
    getAvailableRobotStatus();
  }, []);

  useEffect(() => {
    if (availableStatus) {
      let allStatus = [...availableStatus];
      allStatus.map((item) => (item.description = t(`overseer_app.general.${item.status}`, titleCase(item.status))));
      setAllStatusFormated(allStatus);
    }
  }, [availableStatus]);

  useEffect(() => {
    if (resetForm) {
      setSelectedMotive(null);
      setOtherMotive('');
      setResetForm(false);
    }
  }, [resetForm]);

  useEffect(() => {
    if (robotStateMotives && newState) {
      const statusMotive = robotStateMotives.reduce((bef, acc) => { return { ...bef, [acc.status]: bef[acc.status] ? bef[acc.status].concat([{ ...acc }]) : [{ ...acc }] } }, {})
      setStatusMotive(statusMotive);
      setSelectedMotive(null);
    }
  }, [robotStateMotives, newState]);

  useEffect(() => {
    if (selectedMotive === 'Other' && otherMotive !== '') {
      setMotive(otherMotive);
    } else setMotive(selectedMotive);
  }, [selectedMotive, otherMotive]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (newState === null) {
      console.log('Por favor, completa todos los campos requeridos');
      return;
    }
    await updateRobotState(robotCode, newState.status, motive?.description || '', comment);
  };

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth={'sm'}
      onClose={() => setOpenState(false)}
      aria-labelledby="state-dialog-title"
      aria-describedby="state-dialog-description"
    >
      <DialogTitle id="state-dialog-title">
        {t(
          'overseer_app.widget.robot_state_dialog_title',
          'Change the robot state'
        )}
        <Divider />
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12}>
              <Selector
                options={allStatusFormated}
                selectedElement={newState}
                setSelectedElement={setNewState}
                labelInput={'New State'}
                optionLabel={'description'}
                optionsDisabled={(option) => option.status === robotState}
              />
            </Grid>
            <Grid item xs={12}>
              <Selector
                disabled={!statusMotive[newState?.status]}
                options={statusMotive[newState?.status] || []}
                selectedElement={selectedMotive}
                setSelectedElement={setSelectedMotive}
                labelInput={'Motive'}
                optionLabel={'description'}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography sx={{ marginLeft: 1 }}>Reasons/Comments</Typography>
              <TextField
                value={comment}
                size="small"
                multiline
                minRows={3}
                maxRows={5}
                fullWidth
                sx={{ backgroundColor: 'white' }}
                onChange={(e) => setComment(e.target.value)}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleSubmit}
          disabled={loadingNewRobotState || !newState?.status || (!selectedMotive && statusMotive[newState?.status])}
          color="primary"
        >
          {loadingNewRobotState ?
            <CircularProgress size={24} color="secondary" />
            : t('overseer_app.robot_view.send', 'Send')
          }
        </Button>
      </DialogActions>
    </Dialog>
  );
}
