import React, { useEffect, useState } from 'react'

import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControlLabel, Link, Stack, Switch, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { isEmptyOrUndefined } from '@zippeditoolsjs/blocks';

import { secretManagerURL } from '../Utils';

export default function EditDialog(props) {
  const {
    t,
    openEditDialog,
    setOpenEditDialog,
    parameter,
    selectedParameter,
    robotByUUID,
    isSingleRobotSelected,
    changes,
    setChanges,
    selectedRobots,
    oldParameters,
    setOldParameters,
  } = props;
  const theme = useTheme();
  const isSmDownBreakpoint = useMediaQuery(theme.breakpoints.down("sm"))
  const [input, setInput] = useState({}); // Each key-value pair is robot_uuid: parameter value. E.g.: {"8c26bf65-73be-4a5e-ab1d-1bea1e135cdb": "43.2", "fd9e29bd-fad5-4ab3-bb29-c944d19eab51": "50"}
  const [allRobotsInput, setAllRobotsInput] = useState(''); // String with the value for all the robots and for when 1 robot is selected
  const [checkedSwitch, setCheckedSwitch] = useState(true);

  // Lifecycle methods

  useEffect(() => {
    // If when there are existing changes for the selected parameter use them, otherwise use the value in selectedParameter (the original value)
    const checkExistingChanges = (robotChanges, currentParamValue) => {
      if (!isEmptyOrUndefined(robotChanges, 'object') && (!isEmptyOrUndefined(robotChanges[selectedParameter?.code], 'id') || !isEmptyOrUndefined(robotChanges[selectedParameter?.code], 'object'))) {
        return robotChanges[selectedParameter?.code]
      } else {
        return currentParamValue
      }
    }

    if (openEditDialog) {
      if (isSingleRobotSelected) {
        const initialValue = checkExistingChanges(changes.edit[selectedRobots?.[0]?.robot_uuid], selectedParameter.value)
        setAllRobotsInput(initialValue)
      } else {
        let initialValues = {}
        selectedParameter.values.forEach(robot => {
          initialValues[robot.robot_uuid] = checkExistingChanges(changes.edit[robot.robot_uuid], robot.value)
        })
        setInput(initialValues)
      }
    }
  }, [openEditDialog]);

  // Methods

  const handleInputChange = (value, robot_uuid) => {
    setInput((prev) => ({
      ...prev,
      [robot_uuid]: value
    }));
  };

  const handleSaveChanges = () => {
    let oldEdits = changes.edit
    let newEdits = {};
    let oldParams = oldParameters;

    const updateChangesObjs = (robotUUID, currentParamValue) => {
      let robotEditPrevState = oldEdits[robotUUID];
      let oldRobotParams = oldParams[robotUUID];
      const newParamValue = checkedSwitch ? allRobotsInput : input[robotUUID];

      // Cast to String to be able to compare lists easily
      const areValuesEqual = parameter.data_type === 'secret' ? JSON.stringify(newParamValue) === JSON.stringify(currentParamValue) : String(newParamValue) === String(currentParamValue);

      // If the new value is different from the old value, update the parameter
      if (!areValuesEqual) {
        newEdits[robotUUID] = {
          ...robotEditPrevState,
          [selectedParameter?.code]: newParamValue,
        };
        // Because the param is changed, remove it from oldParameters 
        if (Object.keys(oldRobotParams).length > 1) {
          delete oldRobotParams[selectedParameter?.code]
        } else {
          delete oldRobotParams[robotUUID]
        }
        // If the new value is the same as the old value, remove the parameter from the update
      } else if (robotEditPrevState && selectedParameter?.code in robotEditPrevState) {
        // Add the value to oldParameters when it's no longer edited
        oldParams[robotUUID] = {
          ...oldRobotParams,
          [selectedParameter?.code]: newParamValue,
        };
        // If there's more the one edited parameter for the robot, delete the parameter,
        // else delete the robot object from changes.edit
        if (Object.keys(robotEditPrevState).length > 1) {
          delete robotEditPrevState[selectedParameter?.code]
        } else {
          delete oldEdits[robotUUID]
        }
      }
    }

    if (isSingleRobotSelected) {
      updateChangesObjs(selectedRobots?.[0]?.robot_uuid, selectedParameter.value)
    } else {
      selectedParameter.values.forEach((robot) => {
        updateChangesObjs(robot.robot_uuid, robot.value)
      });
    }

    setChanges(prevState => ({
      ...prevState,
      edit: {
        ...oldEdits,
        ...newEdits
      }
    }))

    setOldParameters(oldParams)

    // Reset the local variables and close the dialog
    handleClose();
  };

  const handleClose = () => {
    setOpenEditDialog(false);
    setInput({});
    setAllRobotsInput('');
  }

  const getDataType = (dataType) => {
    if (dataType === 'int' || dataType === 'float') return 'number'
    return ''
  }

  const setDataType = (value, dataType) => {
    if (value) {
      if (dataType === 'int') {
        return parseInt(value)
      } else if (dataType === 'float') {
        return parseFloat(value)
      } else if (dataType === 'bool') {
        return value === 'true'
      } else if (dataType === 'list') {
        return value.replace(/\s/g, '').split(',')
      } else {
        return String(value)
      }
    }
    return value
  }

  return (
    <Dialog
      fullWidth
      maxWidth={isSingleRobotSelected ? 'sm' : 'md'}
      open={openEditDialog}
      onClose={handleClose}
    >
      {/* Dialog title */}
      <DialogTitle>
        <Stack direction='row' justifyContent='space-between'>
          {t('overseer_app.parameters.Parameter', 'Parameter')}: {selectedParameter?.code}
          {!isSingleRobotSelected &&
            <FormControlLabel
              control={
                <Switch color="primary"
                  checked={checkedSwitch}
                  onChange={(event) => setCheckedSwitch(event.target.checked)}
                />
              }
              label={t('overseer_app.parameters.Edit_All_Robots', 'Edit All Robots')}
              labelPlacement="end"
            />
          }
        </Stack>
      </DialogTitle>
      {/* Dialog content */}
      <DialogContent dividers>
        <Stack spacing={2}>
          {/* Alert: edit warning */}
          {!isSingleRobotSelected && <Alert severity='warning'>{t('overseer_app.parameters.editing_warning', 'Only robots with an assigned value for this parameter will be shown and updated.')}</Alert>}
          {/* Alert: info with data type */}
          {<Alert severity='info'>{t('overseer_app.parameters.Data_type', 'Data type')}: {String(parameter.data_type)}. {parameter.data_type === 'list' && t('overseer_app.parameters.list_format_warning', 'Please separate values with commas.')}</Alert>}
          {/* Default value */}
          <Typography><strong>{t('overseer_app.parameters.Defualt_value', 'Default value')}: </strong>
            {isEmptyOrUndefined(parameter?.default_value, 'id') ?
              <>{t('overseer_app.parameters.No_value_assigned', 'No value assigned.')}</>
              :
              String(parameter?.default_value)
            }
          </Typography>
          {/* Single Robot */}
          {isSingleRobotSelected ?
            // Data type: secret
            parameter.data_type === 'secret' ?
              <Stack spacing={1}>
                <Typography sx={{ fontWeight: 'bold' }}>{robotByUUID[selectedRobots?.[0]?.robot_uuid]?.code}</Typography>
                <Typography>
                  {t('overseer_app.parameters.secret_manager_link_part_1', 'Edit the referenced content in the ')}
                  <Link target="_blank" href={secretManagerURL(allRobotsInput?.project_id, allRobotsInput?.secret_id)}>
                    {t('overseer_app.parameters.secret_manager_link_part_2', 'Secret Manager UI.')}
                  </Link>
                </Typography>
                <TextField
                  label='project_id'
                  value={allRobotsInput?.project_id || ''}
                  onChange={(event) => setAllRobotsInput(prevState => ({
                    ...prevState,
                    'project_id': event.target.value
                  }))}
                  helperText={`${t('overseer_app.parameters.Old_value', 'Old value')}: ${selectedParameter.value?.project_id}`}
                />
                <TextField
                  label='secret_id'
                  value={allRobotsInput?.secret_id || ''}
                  onChange={(event) => setAllRobotsInput(prevState => ({
                    ...prevState,
                    'secret_id': event.target.value
                  }))}
                  helperText={`${t('overseer_app.parameters.Old_value', 'Old value')}: ${selectedParameter?.value?.secret_id}`}
                />
                <TextField
                  label='version_id'
                  value={allRobotsInput?.version_id || ''}
                  onChange={(event) => setAllRobotsInput(prevState => ({
                    ...prevState,
                    'version_id': event.target.value
                  }))}
                  helperText={`${t('overseer_app.parameters.Old_value', 'Old value')}: ${selectedParameter?.value?.version_id}`}
                />
              </Stack>
              :
              // Data type: normal
              <TextField
                key='single-robot-input'
                type={getDataType(parameter.data_type)}
                label={t('overseer_app.parameters.New_value', 'New value')}
                value={allRobotsInput || ''}
                onChange={(event) => setAllRobotsInput(setDataType(event.target.value, parameter.data_type))}
                helperText={`${t('overseer_app.parameters.Old_value', 'Old value')}: ${selectedParameter.value}`}
              />
            :
            // Multiple robots
            <Grid>
              <Grid container spacing={{ xs: 5, sm: 0 }} columns={{ xs: 4, sm: 8, md: 12 }} justifyContent='space-between'>
                {/* All robots */}
                <Grid xs={4} sm={3} md={5} size={{ xs: 12, md: 5 }}>
                  <Stack spacing={1}>
                    <Typography sx={{ fontWeight: 'bold' }}>{t('overseer_app.parameters.All_Robots', 'All Robots')}</Typography>
                    {parameter.data_type === 'secret' ?
                      // Data type: secret
                      <Stack spacing={1}>
                        <TextField
                          disabled={!checkedSwitch}
                          label='project_id'
                          value={allRobotsInput.project_id || ''}
                          onChange={(event) => setAllRobotsInput(prevState => ({
                            ...prevState,
                            'project_id': event.target.value
                          }))}
                        />
                        <TextField
                          disabled={!checkedSwitch}
                          label='secret_id'
                          value={allRobotsInput?.secret_id || ''}
                          onChange={(event) => setAllRobotsInput(prevState => ({
                            ...prevState,
                            'secret_id': event.target.value
                          }))}
                        />
                        <TextField
                          disabled={!checkedSwitch}
                          label='version_id'
                          value={allRobotsInput?.version_id || ''}
                          onChange={(event) => setAllRobotsInput(prevState => ({
                            ...prevState,
                            'version_id': event.target.value
                          }))}
                        />
                      </Stack>
                      :
                      // Data type: normal
                      <TextField
                        disabled={!checkedSwitch}
                        key='all-robots-input'
                        type={getDataType(parameter.data_type)}
                        label={t('overseer_app.parameters.New_value', 'New value')}
                        value={allRobotsInput || ''}
                        onChange={(event) => setAllRobotsInput(setDataType(event.target.value, parameter.data_type))}
                      />}
                  </Stack>
                </Grid>
                {/* Divider */}
                <Divider flexItem orientation={isSmDownBreakpoint ? "horizontal" : "vertical"} sx={isSmDownBreakpoint ? { width: '92%', margin: 'auto' } : {}} />
                {/* Individual robots */}
                <Grid xs={4} sm={4} md={6} flexGrow={1} size={{ xs: 12, md: 6 }}>
                  <Stack spacing={1}>
                    <Typography sx={{ fontWeight: 'bold' }}>{t('overseer_app.parameters.Individual_Robots', 'Individual Robots')}</Typography>
                    {selectedParameter.values?.map(robot => {
                      // Data type: secret
                      if (parameter.data_type === 'secret') {
                        return (
                          <Stack key={robot.id} spacing={1}>
                            <Typography sx={{ fontWeight: 'bold' }}>{robotByUUID[selectedRobots?.[0]?.robot_uuid]?.code}</Typography>
                            <Typography>
                              {t('overseer_app.parameters.secret_manager_link_part_1', 'Edit the referenced content in the ')}
                              <Link target="_blank" href={secretManagerURL(input[robot.robot_uuid]?.project_id, input[robot.robot_uuid]?.secret_id)}>
                                {t('overseer_app.parameters.secret_manager_link_part_2', 'Secret Manager UI.')}
                              </Link>
                            </Typography>
                            <TextField
                              disabled={checkedSwitch}
                              label='project_id'
                              value={input[robot.robot_uuid]?.project_id || ''}
                              onChange={(event) => handleInputChange({ ...input[robot.robot_uuid], 'project_id': event.target.value }, robot.robot_uuid)}
                              helperText={`${t('overseer_app.parameters.Old_value', 'Old value')}: ${robot.value?.project_id}`}
                            />
                            <TextField
                              disabled={checkedSwitch}
                              label='secret_id'
                              value={input[robot.robot_uuid]?.secret_id || ''}
                              onChange={(event) => handleInputChange({ ...input[robot.robot_uuid], 'secret_id': event.target.value }, robot.robot_uuid)}
                              helperText={`${t('overseer_app.parameters.Old_value', 'Old value')}: ${robot.value?.secret_id}`}
                            />
                            <TextField
                              disabled={checkedSwitch}
                              label='version_id'
                              value={input[robot.robot_uuid]?.version_id || ''}
                              onChange={(event) => handleInputChange({ ...input[robot.robot_uuid], 'version_id': event.target.value }, robot.robot_uuid)}
                              helperText={`${t('overseer_app.parameters.Old_value', 'Old value')}: ${robot.value?.version_id}`}
                            />
                          </Stack>
                        )
                      } else {
                        // Data type: normal
                        return (
                          <TextField
                            disabled={checkedSwitch}
                            key={robot.id}
                            type={getDataType(parameter.data_type)}
                            label={robotByUUID[robot.robot_uuid]?.code}
                            value={input[robot.robot_uuid] || ''}
                            onChange={(event) => handleInputChange(setDataType(event.target.value, parameter.data_type), robot.robot_uuid)}
                            helperText={`${t('overseer_app.parameters.Old_value', 'Old value')}: ${robot.value}`}
                          />
                        )
                      }
                    }
                    )}
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
          }
        </Stack>
      </DialogContent>
      {/* Dialog actions */}
      <DialogActions>
        <Button autoFocus onClick={handleClose}>
          {t('overseer_app.general.Cancel', 'Cancel')}
        </Button>
        <Button onClick={handleSaveChanges}>{t('overseer_app.general.Save', 'Save')}</Button>
      </DialogActions>
    </Dialog >
  )
}
