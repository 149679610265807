import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CartesianGrid, ComposedChart, Dot, Line, ReferenceArea, Scatter, Tooltip, XAxis, YAxis } from 'recharts';
import 'tailwindcss/tailwind.css';
import { BaseColors } from './constants';
import { getColorClassNames, tremorTwMerge } from './lib';
import { colorPalette, themeColorRange } from './theme';
import { capitalizeFirstLetter, hasOnlyOneValueForThisKey } from './utils';


const constructCategoryColors = (categories, colors) => {
  const categoryColors = new Map();
  categories.forEach((category, idx) => {
    categoryColors.set(category, colors[idx]);
  });
  return categoryColors;
};

const ScatterChartComponent = (props) => {
  let {
    points,
    styleColors = {},
    yAxisValueName = {},
    showTooltip = true,
    referenceAreas = [],
    onValueChange = () => { },
    heightComposedChart = 300,
    widthComposedChart = 2500,
    linesInfo = [{ lineName: 'line_y', lineKey: 'line_y' }],
    scatterInfo = [{ scatterName: 'scatter', scatterKey: 'scatter' }],
  } = props;

  const { t } = useTranslation();

  const categoryColors = constructCategoryColors(['actions'], themeColorRange);
  const [finalData, SetFinalData] = useState([]);
  const [activeDot, setActiveDot] = useState(undefined);
  const [activeLegend, setActiveLegend] = useState(undefined);
  const [margin, setMargin] = useState({ top: 10, right: 70, bottom: 30, left: 10 });
  const hasOnValueChange = !!onValueChange;

  useEffect(() => {
    if (points?.length) {
      const newPoints = points;
      SetFinalData(newPoints);
      handleMargin(newPoints[0].x);
    }
  }
    , [points]);


  // handlers
  const handleScatterClick = (event) => {
    onValueChange?.({
      eventType: "scatter",
      ...event.payload,
    });
  }

  const handlePayloadValue = (payload, totalElements, index) => {
    let tooltipDescription = '';
    if (totalElements - index === 1) {
      tooltipDescription = payload?.z;
    } else {
      tooltipDescription = payload?.z;
    }
    return tooltipDescription;
  }

  const onDotClick = (itemData, event) => {
    event.stopPropagation();
    if (!hasOnValueChange) return;
    if (
      (itemData.index === activeDot?.index && itemData.dataKey === activeDot?.dataKey) ||
      (hasOnlyOneValueForThisKey(finalData, itemData.dataKey) &&
        activeLegend &&
        activeLegend === itemData.dataKey)
    ) {
      setActiveLegend(undefined);
      setActiveDot(undefined);
      onValueChange?.(null);
    } else {
      setActiveLegend(itemData.dataKey);
      setActiveDot({
        index: itemData.index,
        dataKey: itemData.dataKey,
      });
      onValueChange?.({
        eventType: "dot",
        categoryClicked: itemData.dataKey,
        ...itemData.payload,
      });
    }
  }

  const handleStyleColor = (payload, payloadItem, targetStyle, defaultColor = '#6b7280') => {
    const styles = payload?.styles;
    let color = defaultColor;
    if (styles) {
      if (targetStyle) {
        color = styles?.[targetStyle] ?? color;
      } else {
        if (styles.alert) {
          color = styles.alertDotFill;
        }
      }
    }
    return color
  }

  const handleMargin = (x) => {
    const xLength = CustomXTickFormatter(x).length;
    const visibilityRatioY = 5.3;
    const visibilityRatioX = 6.4;
    const maxNameLength = Object.values(yAxisValueName).reduce((acc, curr) => curr?.name?.length > acc ? curr?.name?.length : acc, 0);
    const left = Math.max(xLength, maxNameLength) * visibilityRatioX;
    const top = 10;
    setMargin({ top, right: 70, bottom: xLength * visibilityRatioY, left });
  }

  const CustomXTickFormatter = (timeString) => {
    const xTime = timeString.split(' ');
    return xTime[1];
  };

  const CustomYTickFormatter = (value) => {
    return yAxisValueName[value]?.name ?? value;
  }

  const handleTicks = () => {
    const ticks = Object.keys(yAxisValueName);
    if (ticks.length > 1) {
      return ticks;
    } else if (ticks.length === 1) {
      // '-10' magic number is used to make sure the chart is not empty and the y-axis is visible
      return ['-10', ticks[0]];
    }
    return null;
  }

  return (
    finalData?.length ?
      <Box sx={{ height: 'fit-content', width: 'max-content' }}>
        <ComposedChart
          width={widthComposedChart}
          height={heightComposedChart}
          data={finalData}
          margin={margin}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="x"
            type="category"
            name="Time"
            scale="point"
            angle={-45}
            textAnchor="end"
            tickFormatter={CustomXTickFormatter}
          />
          <YAxis
            type="number"
            name="Action"
            scale={'auto'}
            dataKey={scatterInfo[0]?.scatterKey}
            tickFormatter={CustomYTickFormatter}
            ticks={handleTicks()}
          />
          {/* Tooltip */}
          <Tooltip
            wrapperStyle={{ outline: "none" }}
            isAnimationActive={false}
            cursor={{ stroke: "#d1d5db", strokeWidth: 1 }}
            content={
              showTooltip ? (
                ({ active, payload, label }) =>
                  <div style={{ width: '23em' }} className="w-48 rounded-tremor-default text-tremor-default bg-tremor-background p-2 shadow-tremor-dropdown border border-tremor-border">
                    <div className="flex flex-1 space-x-2.5">
                      <div className={`w-1.5 flex flex-col bg-${payload?.[0]?.color}-500 rounded`} />
                      <div className="w-full">
                        {payload.map((payloadItem, index) => (
                          <div key={`scatter-tooltip-${index}`} className="flex items-center justify-between space-x-2">
                            <Typography className="text-left text-tremor-content whitespace-nowrap">
                              <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                                <svg
                                  className={tremorTwMerge("flex-none h-2 w-2 mr-1.5")}
                                  style={{ color: handleStyleColor(payloadItem.payload, payloadItem, payloadItem?.name, payloadItem?.color) }}
                                  fill="currentColor"
                                  viewBox="0 0 8 8"
                                >
                                  <circle cx={4} cy={4} r={4} />
                                </svg>
                                {capitalizeFirstLetter(payloadItem.name)}:
                              </Typography>
                            </Typography>
                            <p className="font-medium bold text-right whitespace-nowrap text-tremor-content-emphasis">
                              {handlePayloadValue(payloadItem.payload, payload?.length, index)}
                            </p>
                          </div>
                        ))}
                        <p className="text-tremor-content whitespace-nowrap" sx={{ mt: 1 }}>{label}</p>
                      </div>
                    </div>
                  </div>

              ) : (
                <></>
              )
            }
            position={{ y: 0 }}
          />
          {/* Reference Areas */}
          {referenceAreas.map((area, index) => (
            <ReferenceArea
              key={`scatter-area-${index}`}
              x1={area.x1}
              x2={area.x2}
              label={{ value: area.action, position: 'insideTopRight' }}
              fill={area.fill ?? '#82ca9d'}
              opacity={area.opacity ?? 0.3}
            />
          ))}
          {/* Scatter Charts */}
          {
            scatterInfo.map(({ scatterName, scatterKey }, index) => (
              <Scatter
                onClick={handleScatterClick}
                key={`scatter-${index}-name`}
                name={scatterName}
                dataKey={scatterKey}
                color={styleColors?.[scatterKey] ?? '#8884d8'}
                fill={styleColors?.[scatterKey] ?? '#8884d8'} />
            ))
          }
          {/* Lines Charts */}
          {
            linesInfo.map(({ lineName, lineKey, connectNulls }, index) => (
              <Line
                connectNulls={connectNulls ?? true}
                key={index}
                type="step"
                dataKey={lineKey}
                name={lineName}
                stroke={styleColors?.[lineKey] ?? '#8884d8'}
                color={styleColors?.[lineKey] ?? '#8884d8'}
                strokeWidth={2}
                dot={
                  (props: any) => {
                    const { cx, cy, strokeLinecap, strokeLinejoin, payload } = props;
                    return (
                      <Dot
                        cx={cx}
                        cy={cy}
                        r={3}
                        fill={handleStyleColor(payload, null, lineName)}
                        stroke={handleStyleColor(payload, null, lineName)}
                        strokeLinecap={strokeLinecap}
                        strokeLinejoin={strokeLinejoin}
                        strokeWidth={1}

                      />)
                  }
                }
                activeDot={(props: any) => {
                  const { cx, cy, stroke, strokeLinecap, strokeLinejoin, strokeWidth, dataKey } = props;
                  return (
                    <Dot
                      className={tremorTwMerge(
                        "stroke-tremor-background dark:stroke-dark-tremor-background",
                        onValueChange ? "cursor-pointer" : "",
                        getColorClassNames(
                          categoryColors.get(dataKey) ?? BaseColors.Gray,
                          colorPalette.text,
                        ).fillColor,
                      )}
                      cx={cx}
                      cy={cy}
                      r={5}
                      fill=""
                      stroke={stroke}
                      strokeLinecap={strokeLinecap}
                      strokeLinejoin={strokeLinejoin}
                      strokeWidth={strokeWidth}
                      onClick={(dotProps: any, event) => onDotClick(props, event)}
                    />
                  );
                }}
              />
            ))
          }
        </ComposedChart>
      </Box>
      :
      <Box sx={{
        minHeight: '12em',
        background: '#D9D9D9',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
        <Typography>{t('overseer_app.general.no_data', 'No data to display')}</Typography>
      </Box>
  );
};

export default ScatterChartComponent;
