import { connect } from 'react-redux';
import StoreConfig from '../../../components/storeConfig/StoreConfig';
import { getChains, checkFeaturePermission } from 'core/slices/backend';
import {
  getChainsList,
  isLoadingStoreConfigs,
  storeConfigs,
  getNewStoreConfigResponse,
  loadingNewStoreConfigResponse,
  getDeleteStoreResponse,
  isDeletingStore,
  countriesList,
  isLoadingCountries,
  getStoreUpdateResponse,
  isLoadingStoreUpdate,
  gmapsApiKey,
  getFeaturePermission
} from 'core/selectors';
import { setSnackAlert } from 'core/slices/snackAlert';
import {
  getStoreConfigs,
  postStoreConfig,
  deleteStoreConfig,
  getCountries,
  patchStoreConfig,
  getMapsKey,
} from 'core/slices/storeConfig';

export default connect(
  (state) => ({
    storeConfigs: storeConfigs(state),
    isLoadingStoreConfigs: isLoadingStoreConfigs(state),
    chainsList: getChainsList(state),
    newStoreResponse: getNewStoreConfigResponse(state),
    isLoadingNewStoreResponse: loadingNewStoreConfigResponse(state),
    deleteStoreResponse: getDeleteStoreResponse(state),
    isDeletingRobot: isDeletingStore(state),
    countriesList: countriesList(state),
    isLoadingCountries: isLoadingCountries(state),
    storeUpdateResponse: getStoreUpdateResponse(state),
    isLoadingStoreUpdate: isLoadingStoreUpdate(state),
    gmapsApiKey: gmapsApiKey(state),
    featurePermission: getFeaturePermission(state),
  }),
  {
    getChains,
    setSnackAlert,
    getStoreConfigs,
    postStoreConfig,
    deleteStoreConfig,
    getCountries,
    patchStoreConfig,
    getMapsKey,
    checkFeaturePermission
  }
)(StoreConfig);
